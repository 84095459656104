




























































































































































































































import Vue from "vue";
import Layout from "@/router/layouts/main.vue";
import { ToggleButton } from "vue-js-toggle-button";
import BackButton from "@/components/back-button.vue";
import {mapActions, mapGetters} from "vuex";
import router from "@/router";
import { errorMessage } from "@/utils/messages-handlers";

export default Vue.extend({
  name: "edit-user-permissions" as string,

  components: {
    Layout,
    ToggleButton,
    "back-button": BackButton
  },

  data() {
    return {
      item: false
    }
  },

  async mounted(): Promise<void> {
    if(!this.loggedUser.isAdmin){
      await this.setAvailableVenuesForRoleManagementForCurrentUser();
    }
    await this.setSelectedUser();
    await this.checkIfAdmin();
    await this.setUserVenuesPermissions();

    if (!!this.selectedUser.image_last_updated) {
      await this.setImage({id: this.selectedUser.id, imageType: "clientzone_user", selectedUser: true});
    }
  },

  computed: {
    ...mapGetters("userModule", {
      selectedUser: "GET_SELECTED_USER",
      loggedUser: "GET_LOGGED_USER",
      userVenuesPermissions: "GET_USER_VENUES_PERMISSIONS",
      isSelectedUserImage: "IS_SELECTED_USER_IMAGE",
      selectedUserImage: "GET_SELECTED_USER_IMAGE",
      userAuthorisations: "GET_USER_AUTHORISATIONS"
    }),
    ...mapGetters("venueModule", {
      groupAllLoader: "GET_GROUP_ALL_LOADER",
      venues: "GET_VENUES",
      availableVenues: "GET_AVAILABLE_VENUES_FOR_ROLE_MANAGEMENT",
    }),
    ...mapGetters("companyModule", {
      companyId: "GET_COMPANY_ID"
    }),
    userName(): string {
      return this.selectedUser.first_name + " " + this.selectedUser.last_name;
    },
    formatImage(): string {
      return this.item.split(',')[1];
    },
    insertUserImage(): string {
      if (!this.selectedUserImage) return "";
      return `data:image/jpeg;charset=utf-8;base64, ${this.selectedUserImage}`
    },
    isUserCompanyOwner(): boolean {
      return !!this.userAuthorisations.find(a => a.venue_id === null && a.role_id !== 5);
    },
    adminGroupPermissionCheck(): boolean {
      if (!this.isUserCompanyOwner) return this.areAllGroupPermissionsAdded(1);
      return true;
    },
    managerGroupPermissionCheck(): boolean {
      return this.areAllGroupPermissionsAdded(2);
    },
    marketerGroupPermissionCheck(): boolean {
      return this.areAllGroupPermissionsAdded(3);
    },
    accountantGroupPermissionCheck(): boolean {
      return this.areAllGroupPermissionsAdded(4);
    }
  },

  methods: {
    ...mapActions("userModule", {
      setSelectedUser: "FETCH_USER",
      checkIfAdmin: "CHECK_IF_ADMIN",
      setUserVenuesPermissions: "FETCH_USER_VENUES_PERMISSIONS"
    }),
    ...mapActions("globalModule", {
      setImage: "FETCH_ENTITY_IMAGE"
    }),
    ...mapActions("venueModule", {
      addUserPermission: "ADD_USER_PERMISSION",
      removeUserPermission: "REMOVE_USER_PERMISSION",
      removeUserPermissionByGroup: "REMOVE_USER_PERMISSION_BY_GROUP",
      setAvailableVenuesForRoleManagementForCurrentUser: "FETCH_AVAILABLE_VENUES_FOR_ROLE_MANAGEMENT",
    }),
    async togglePermission(value: boolean, roleId: number, venueId: number, userVenueRoleId: number): Promise<void> {
      if (value) {
        const payload: Array<object> = [{
          czone_user_id: this.selectedUser.id,
          role_id: roleId,
          venue_id: venueId
        }]

        await this.addUserPermission({payload: payload});
      } else {
        const payload: object = {
          id: userVenueRoleId,
          venueId: venueId
        }

        await this.removeUserPermission({payload: payload});
      }
    },
    async togglePermissionByRoleGroup(value: boolean, group: string): Promise<void> {
      let payload: Array<object | number> = [] as Array<object | number>;
      const roleGroupSelector = document.querySelectorAll(`[data-role="${group}"]`);
      if (value) {
          for (const t of roleGroupSelector) {
            if (!t.getAttribute('data-value')) {
              let role = null
              const venueId = Number(t.getAttribute('data-venue-id'));
              if(this.loggedUser.isAdmin) {
                role = {
                  czone_user_id: this.selectedUser.id,
                  role_id: Number(t.getAttribute('data-user-role')),
                  venue_id: venueId,
                }
              } else {
                if (this.availableVenues.some(e => e.id === venueId)) {
                  role = {
                    czone_user_id: this.selectedUser.id,
                    role_id: Number(t.getAttribute('data-user-role')),
                    venue_id: venueId,
                  }
                }
              }
             if(role) payload.push(role)
          }
        }
        await this.addUserPermission({payload: payload, group: true});
      } else {
        for (const t of roleGroupSelector) {
          if (t.getAttribute('data-value')) {
            let userVenueRoleId = null
            const venueId = Number(t.getAttribute('data-venue-id'));
            if(this.loggedUser.isAdmin){
              userVenueRoleId = Number(t.getAttribute('data-user-venue-role-id'))
            } else {
              if (this.availableVenues.some(e => e.id === venueId)) {
                userVenueRoleId = Number(t.getAttribute('data-user-venue-role-id'))
              }
            }
            if(userVenueRoleId) payload.push(userVenueRoleId)
          }
        }
        if(!payload.length) {
          errorMessage('All permitted venues are deselected.', 5000);
        }

        await this.removeUserPermissionByGroup({payload: payload, group: true})
      }
    },
    areAllGroupPermissionsAdded(roleId: number): boolean {
      const authorizedVenuesLength: number = this.userAuthorisations.filter(a => a.role_id === roleId).length;
      const venuesLength: number = this.loggedUser.isAdmin ? this.venues.length : this.availableVenues.length;

      return authorizedVenuesLength >= venuesLength;
    },
  },

  watch: {
    companyId: {
      handler(newValue: number, oldValue: number): void {
        if (newValue != oldValue) {
          this.$router.push("/user-access-rights");
        }
      }
    },
    '$route.params.id': {
       handler: async function(){
        await this.setSelectedUser();
        await this.setUserVenuesPermissions();

        if (!!this.selectedUser.image_last_updated) {
          await this.setImage({id: this.selectedUser.id, imageType: "clientzone_user", selectedUser: true});
        }
      },
      deep: true,
      immediate: true
  }
}
});
